import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Logo from "../images/Logobg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faLinkedinIn, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import './Hero.css'
const Footer = () => {
  
  return (
    <>
      <footer>
        <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">
          {/* Top area: Blocks */}
          <div className=" footer grid sm:grid-cols-12 gap-2 py-8 md:py-12 border-t border-gray-200 ml-0">
            {/* 1st block */}
           
            {/* 2nd block */}
            <div className="col-span-3 md:col-span-6 lg:col-span-1  mx-auto">
              <h6 className="text-dark text-xl font-bold mb-4">LINKS</h6>
              <ul className="text-md pl-0 md:pl-4 lg:pl-0">
                {" "}
                {/* Adjust padding to align with the heading */}
                <li className="mb-2">
                  <HashLink
                    to="#"
                    className="text-black hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    About
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="#"
                    className="text-black hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Services
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="/contact"
                    className="text-black hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Contact
                  </HashLink>
                </li>
              </ul>
            </div>
            <div className="col-span-5 md:col-span-6 lg:col-span-3 mx-auto">
              <h6 className="text-dark text-xl font-bold mb-4">OUR SERVICES</h6>
              <ul className="text-md pl-0 md:pl-4 lg:pl-0">
                {" "}
                {/* Adjust padding to align with the heading */}
                <li className="mb-2">
                  <HashLink
                    to="/php"
                    className="text-black hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Web Development
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="/android"
                    className="text-black hover:text-gray-900  transition duration-250 ease-in-out"
                  >
                    Mobile Development
                  </HashLink>
                </li>
                
                <li className="mb-2">
                  <HashLink
                    to="#"
                    className="text-black hover:text-gray-900 hover:tracking transition duration-250 ease-in-out"
                  >
                    Domain and Hosting
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="#"
                    className="text-black hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    General Consult
                  </HashLink>
                </li>
              </ul>
            </div>
<div className="col-span-5 md:col-span-6 lg:col-span-2 mx-auto">
              <h6 className="text-dark text-xl font-bold mb-4">OUR PRODUCTS</h6>
              <ul className="text-md pl-0 md:pl-4 lg:pl-0">
                {" "}
                {/* Adjust padding to align with the heading */}
                <li className="mb-2">
                  <HashLink
                    to="#"
                    className="text-black hover:text-gray-900  transition duration-250 ease-in-out"
                  >
                    THE LINK TREE APP
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="#"
                    className="text-black hover:text-gray-900 transition duration-250 ease-in-out"
                  >
                    Mobile App Development
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="#"
                    className="text-black hover:text-gray-900  transition duration-250 ease-in-out"
                  >
                    Domain and Hosting
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="#"
                    className="text-black hover:text-gray-900 transition duration-250 ease-in-out"
                  >
                    General IT Consultations
                  </HashLink>
                </li>
              </ul>
            </div>
            <div className="col-span-12 lg:col-span-3">
              <div
                style={{ width: "101px", alignItems: "center" }}
                className="box-border border-b-4 border-blue-900 p-8  bg-gray-200  text-center rounded-lg xl:w-60 mx-auto"
              >
                <img
                alt={Image}
                  src={Logo}
                  style={{ width: "200px", alignItems: "center" }}
                />
               
              </div>
            </div>


            <div style={{ }} className="col-span-5 md:col-span-6 lg:col-span-2 mx-auto ">
            <h6 className="text-dark text-xl font-bold mb-4 ml-4 ">Follow us on social media.</h6>
                
              
              <div className="mx-auto text-center mt-2">  
                <ul className="flex justify-center mb-4 md:mb-0 space-x-2">
                  {" "}
                  {/* Added space-x-4 for spacing between icons */}
                  <li>
                  <a target="_blank" rel="noreferrer"  className="text-black" href="https://wa.me/923189650151?text=Hello%20there!" >
                  <FontAwesomeIcon   className="w-8 h-8 fill-current" icon={faWhatsapp}/></a> 
                  </li>
                  <li>
                  <a target="_blank" rel="noreferrer" className="text-black"  href="https://www.instagram.com/unsi.tech?igsh=cDU0aTMxem1pNW5l" >
                  <FontAwesomeIcon  className="w-8 h-8 fill-current" icon={faInstagram}/></a> 
                
                   
                   
                  </li>
                  <li>
                  <a target="_blank" rel="noreferrer" className="text-black"  href="https://www.linkedin.com/company/unsi-tech/" >
                  <FontAwesomeIcon  className="w-8 h-8 fill-current" icon={faLinkedinIn}/></a> 
                
                  </li>
                  <li>
                  <a target="_blank" className="text-black"  href="https://www.facebook.com/profile.php?id=61556704140161&mibextid=ZbWKwL" rel="noreferrer" >
                  <FontAwesomeIcon  className="w-8 h-8 fill-current" icon={faFacebook}/></a> 
                
                  </li>

                </ul>
            </div>
            {/* 4th block */}
            
            </div>  
      
          </div>
          <div><Link style={{textDecoration:'none' , display:'flex' , justifyContent:'end' , alignItems:'end'}} className=" text-black hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out mx-auto" to="privacy-policy">PrivacyPolicy</Link></div>

        </div>
        <div
          style={{ backgroundColor: "#241E6F", width: "100%" }}
          className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4"
        >
          <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
            <div className="text-sm text-gray-200 font-semibold py-1">
              Copyright &copy; {new Date().getFullYear()}
              {"  "}
              <HashLink to="#" className=" hover:text-gray-900">
                UNSI TECH
              </HashLink>
              . All rights reserved.
            </div>
          </div>
        </div>
        
      </footer>
    </>
  );
};
export default Footer;
