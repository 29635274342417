import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import { Button } from "react-bootstrap";
import labimg from "../images/lab/labimage.jpeg";
import labimg2 from "../images/lab/labimg2.jpeg";
import labimg5 from "../images/lab/labimg5.webp";
import labimg4 from "../images/lab/labimg4.jpeg";
import NavBar from "../components/Navbar/NavBar";
import Container from "react-bootstrap/Container";
import Footer from "../components/Footer";
import MetaTags from "react-meta-tags";
function LabSystem() {
  const labs = [
    {
      id: 1,
      name: "Manage Patient Profile",
    },
    {
      id: 2,
      name: "Manage Doctor Profile",
    },
    {
      id: 3,
      name: "Manage Doctor Commission",
    },
    {
      id: 4,
      name: "Manage Culture",
    },
    {
      id: 5,
      name: "Manage Tests",
    },
    {
      id: 6,
      name: "Manage Lab Inventory",
    },
    {
      id: "7",
      name: "Patient Appointment",
    },
    {
      id: 8,
      name: "Appointment History",
    },
    {
      id: 9,
      name: "Patient Medical Record",
    },
    {
      id: 10,
      name: "Manage Payments",
    },
    {
      id: 11,
      name: "Multi User Role",
    },
  ];
  return (
    <div>
      <NavBar />
      <Container fluid style={{ padding: "0", margin: "0", maxWidth: "100%" }}>
        <MetaTags>
          <title>UNSI | Lab management system</title>
          <meta name="LSM SERVICE" content="Some description." />
          <meta property="og:title" content="MyApp" />
          <meta property="og:image" content="path/to/image.jpg" />
        </MetaTags>
        <Row
          className="data-section"
          data-aos="fade-right"
          style={{ margin: "0", paddingTop: "70px" }}
        >
          <Col
            xs={12}
            md={8}
            lg={6}
            style={{ paddingTop: "25px", padding: "20px" }}
          >
            <h1
              className="text-blue-900"
              style={{
                textAlign: "left",
                fontSize: "2.5rem",
                fontWeight: "bold",
                marginTop: "32px",
              }}
            >
              BEST LAB SYSTEM MANAGEMENT
            </h1>
            <p>
              UNSI Tech presents the Best Lab Management System, designed to
              streamline and optimize laboratory operations. Powered by
              cutting-edge ERP software, this system offers seamless management
              of patient profiles, lab tests, inventory, and much more, ensuring
              efficiency and accuracy in every aspect of lab management.
            </p>
          </Col>
          <Col
            xs={12}
            md={8}
            lg={6}
            style={{
              paddingTop: "25px",
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={labimg4}
              alt="Hospital Management"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Col>
        </Row>

        <Row
          style={{ margin: "0", paddingTop: "50px", minHeight: "100vh" }}
          className="data-section"
        >
          <Col xs={12}>
            <h2
              style={{
                textAlign: "center",
                color: "white",
                fontSize: "1.8rem",
              }}
            >
              Main Key Features
            </h2>
          </Col>

          {labs.map((lab, index) => (
            <Col
              xs={6}
              sm={4}
              md={3}
              key={lab.id}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#0D47A1",
                  color: "#fff",
                  height: "60px",
                  width: "300px",
                  borderRadius: "12px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                <span
                  style={{
                    fontSize: "20",
                    fontWeight: "bold",
                    padding: 2,
                    textAlign: "center",
                  }}
                >
                  {lab.name}
                </span>
              </div>
            </Col>
          ))}
          <Col
            xs={12}
            sm={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              variant="success"
              style={{
                width: 200,
                height: 60,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Book a Demo
            </Button>
          </Col>
        </Row>

        <Row
          data-aos="fade-left"
          style={{ padding: "10px", paddingTop: "80px" }}
        >
          <Col
            xs={12}
            md={8}
            lg={6}
            style={{ padding: "15px", textAlign: "left" }}
          >
            <h1
              style={{
                fontSize: "1.5rem",
                fontWeight: "bolder",
                margin: "12px",
              }}
            >
              Streamlined Operations
            </h1>
            <p style={{ fontSize: "1rem", display: "flex", margin: "12px" }}>
              Our Lab Management System simplifies and automates everyday lab
              functions, allowing laboratories to run smoothly and deliver fast,
              accurate results.
            </p>
            <ul style={{ marginTop: "2", listStyleType: "disc" }}>
              <li>
                
                <strong style={{ display: "inline" }}>
                  Managing Patient Profiles –
                </strong>
                Store and access patient data securely for accurate and
                efficient lab testing.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                  Managing Doctor Profiles -
                </strong>
                st Keep comprehensive records of doctors' specialties and test
                orders for better collaboration.
              </li>
              <li>
                
                <strong style={{ display: "inline" }}>
                  Managing Doctor Commissions -
                </strong>
                Automate commission calculations for transparent and timely
                payments to doctors.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                  Culture Management -
                </strong>
                Manage the entire lifecycle of cultures, from collection to
                accurate results and reports.
              </li>
            </ul>
          </Col>
          <Col
            xs={12}
            md={4}
            lg={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <img
              src={labimg5}
              alt="PHP Logo"
              style={{ width: "60%", height: "auto" }}
            />
          </Col>
        </Row>

        <Row data-aos="fade-left" style={{ margin: "20px", padding: "10px" }}>
          <Col
            xs={12}
            md={6}
            lg={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <img
              src={labimg2}
              alt="PHP Logo"
              style={{ width: "90%", height: "auto" }}
            />
          </Col>
          <Col
            xs={12}
            md={6}
            lg={6}
            style={{
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <h1 style={{ fontSize: "1.5rem", fontWeight: "bolder" }}>
              Data-Driven Efficiency
            </h1>
            <p style={{ fontSize: "1rem", display: "flex", margin: "12px" }}>
              With powerful data tools integrated into the system, UNSI Tech’s
              Lab Management System empowers labs to make informed decisions,
              improve accuracy, and boost productivity.
            </p>
            <ul style={{ marginTop: "2", listStyleType: "disc" }}>
              <li>
                
                <strong style={{ display: "inline" }}>
                  Managing Tests –
                </strong>
                Track, process, and deliver lab test results efficiently with
                automated systems.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                  Managing Lab Inventory –
                </strong>
                Monitor inventory in real-time and automate reordering to avoid
                shortages.
              </li>
              <li>
                
                <strong style={{ display: "inline" }}>
                  Patient Appointment Management -
                </strong>
                Easily manage patient appointments with automated
                reminders and scheduling.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                  Appointment History –
                </strong>
                Access a complete history of patient appointments and lab tests
                for seamless continuity of care.
              </li>
            </ul>
          </Col>
        </Row>

        <Row
          data-aos="fade-left"
          style={{ margin: "0", padding: "10px", position: "relative" }}
        >
          <Col
            xs={12}
            md={6}
            lg={6}
            style={{
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              textAlign: "left",
              backgroundColor: "beige",
              position: "relative",
            }}
          >
            <h1 style={{ fontSize: "1.5rem", fontWeight: "bolder" }}>
              Enhanced Patient Care
            </h1>
            <p style={{ fontSize: "1rem", display: "flex", margin: "12px" }}>
              UNSI Tech’s Lab Management System is designed to improve patient
              care by providing faster results, ensuring data security, and
              enhancing the overall experience of patients and healthcare
              professionals alike.
            </p>
            <ul style={{ marginTop: "2", listStyleType: "disc" }}>
              <li>
                <strong style={{ display: "inline" }}>
                  Managing Patient Medical Records –
                </strong>
                Keep secure, organized records for accurate and quick access to
                patient information.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                  Managing Payments –
                </strong>
                Streamline payments with an integrated billing system and
                real-time payment tracking
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                  Multi-User Roles –
                </strong>
                Provide secure access for multiple users with role-based
                permissions for lab staff and administrators.
              </li>
              <li>
                <strong style={{ display: "inline" }}>Data Security –</strong>
                Protect sensitive data with advanced security measures to ensure
                compliance and privacy.
              </li>
            </ul>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={6}
            style={{
              padding: "0",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              position: "relative",
            }}
          >
            <img
              src={labimg}
              alt="PHP Logo"
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
            />
          </Col>
        </Row>

        <Footer />
      </Container>
    </div>
  );
}

export default LabSystem;
