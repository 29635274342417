import React, { useState } from 'react';
import NavBar from '../Navbar/NavBar';
import Footer from '../Footer';
import { useDocTitle } from '../CustomHook';
import MetaTags from "react-meta-tags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedinIn, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { postContactForm } from '../../Utils/Api';

import swal from 'sweetalert';
const Contact = () => {
  useDocTitle('UNSI | UNSI TECH - Send us a message');

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("Sending form data:", formData);
      setFormData({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        message: '',
      });
      const response = await postContactForm(formData);
      
      console.log("API response:", response); // Log the API response
      // alert('Message sent successfully!'); // Alert success
      
      swal({
        title: "Thank You",
        text: "Your Query has been sent successfully! Our team will Contact You Soon!",
        icon: "success",
        dangerMode: false,
      })
     
      
      
    } catch (error) {
      console.error('Error sending message:', error); // Log the full error object
      swal('Failed to send the message, please try again.');
    }
  };
  
  

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div id='contact' className="flex justify-center items-center mt-8 w-full bg-white py-1 lg:py-24 ">
        <MetaTags>
          <title>UNSI | Contact</title>
          <meta name="POS SERVICE" content="Some description." />
          <meta property="og:title" content="MyApp" />
          <meta property="og:image" content="path/to/image.jpg" />
        </MetaTags>

        <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
          <form onSubmit={handleSubmit}>
            <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
              <div className="flex">
                <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">Send us a message</h1>
              </div>
              <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                <div>
                  <input 
                    name="first_name" 
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="text" 
                    placeholder="First Name*" 
                    value={formData.first_name}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <input 
                    name="last_name" 
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="text" 
                    placeholder="Last Name*" 
                    value={formData.last_name}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <input 
                    name="email"
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="email" 
                    placeholder="Email*" 
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <input
                    name="phone" 
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="text" 
                    placeholder="Phone*" 
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="my-4">
                <textarea 
                  name="message" 
                  placeholder="Message*" 
                  className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="my-2 w-1/2 lg:w-2/4">
                <button type="submit" id="submitBtn" className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                        focus:outline-none focus:shadow-outline">
                  Send Message
                </button>
              </div>
            </div>
          </form>

          {/* Contact Info Section */}
          <div className="w-full lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-blue-900 rounded-2xl">
            <div className="flex flex-col text-white">
              <div className="flex my-4 w-2/3 lg:w-3/4">
                <div className="flex flex-col">
                  <i className="fas fa-map-marker-alt pt-2 pr-2" />
                </div>
                <div className="flex flex-col">
                  <h2 className="text-2xl">Office Address</h2>
                  <p className="text-gray-400">20 Wetherby Close, Birmingham, West Midlands, B36 8QA, UNITED KINGDOM, Shan Arcade Plaza , Barkat Market , Lahore Pakistan</p>
                </div>
              </div>
              <div className="flex my-4 w-2/3 lg:w-1/2">
                <div className="flex flex-col">
                  <i className="fas fa-phone-alt pt-2 pr-2" />
                </div>
                <div className="flex flex-col">
                  <h2 className="text-2xl">Call Us</h2>
                  <p className="text-gray-400">+92 318 965015  +44 7470790717</p>
                  <div className="mt-5">
                    <h2 className="text-2xl">Send an E-mail</h2>
                    <p className="text-gray-400">info@uns.itech</p>
                  </div>
                </div>
              </div>
              <div className="flex my-4 w-2/3 lg:w-1/2">
                <a href="https://www.instagram.com/unsi.tech?igsh=cDU0aTMxem1pNW5l">
                  <FontAwesomeIcon className="w-8 h-8 me-2 bg-light" icon={faInstagram} />
                </a>
                <a href="https://www.linkedin.com/company/unsi-tech/">
                  <FontAwesomeIcon className="w-8 h-8 bg-light me-2" icon={faLinkedinIn} />
                </a>
                <a href="https://wa.me/923189650151?text=Hello%20there!">
                  <FontAwesomeIcon className="w-8 h-8 me-2 bg-light" icon={faWhatsapp} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
