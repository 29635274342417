import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import img1 from "../images/POS/POSMAIN.jpeg";
import img2 from "../images/POS/ledger.png";
import img3 from "../images/POS/Barcode-Scanning-in-the-Warehouse-scaled.jpeg";
import NavBar from "../components/Navbar/NavBar";
import Container from "react-bootstrap/Container";
import Footer from "../components/Footer";
import { Button } from "react-bootstrap";
import MetaTags from "react-meta-tags";
function POS() {
  const labs = [
    {
      id: 1,
      name: "Item Profile",
    },
    {
      id: 2,
      name: "Customer Profile",
    },
    {
      id: 3,
      name: "Supplier Profile",
    },
    {
      id: 4,
      name: "Purchase Voucher",
    },
    {
      id: 5,
      name: "Purchase Return",
    },
    {
      id: 6,
      name: "Sales Return",
    },
    {
      id: "7",
      name: "Credit/Cash Sales",
    },
    {
      id: 8,
      name: "Outstanding Invoices",
    },
    {
      id: 9,
      name: "Invoice Aging",
    },
    {
      id: 10,
      name: "Customer Payments",
    },
    {
      id: 11,
      name: "Supplier Payments",
    },
    {
      id: 12,
      name: "Expense Voucher",
    },
  ];

  return (
    <div>
      <NavBar />
      <Container fluid style={{ padding: "0", margin: "0", maxWidth: "100%" }}>
        <MetaTags>
          <title>UNSI | POS (Cloud)</title>
          <meta name="HMS SERVICE" content="Some description." />
          <meta property="og:title" content="MyApp" />
          <meta property="og:image" content="path/to/image.jpg" />
        </MetaTags>
        <Row
          className="data-section"
          data-aos="fade-right"
          style={{ margin: "0", paddingTop: "70px" }}
        >
          <Col
            xs={12}
            md={8}
            lg={5}
            style={{ paddingTop: "15px", padding: "20px" }}
          >
            <h1
              className="text-blue-900"
              style={{
                textAlign: "left",
                fontSize: "2.5rem",
                fontWeight: "bold",
                marginTop: "32px",
              }}
            >
              BEST POS SYSTEM MANAGEMENT CLOUD BASED
            </h1>
            <p>
              UNSI Tech introduces the Best POS System Management (Cloud-Based),
              designed to optimize sales, inventory, and financial processes for
              hospital management. Powered by robust ERP software, this
              cloud-based system offers real-time access to essential business
              operations, ensuring efficiency and accuracy across all
              transactions
            </p>
          </Col>
          <Col
            xs={12}
            md={8}
            lg={7}
            style={{
              paddingTop: "0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={img1}
              alt="Hospital Management"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Col>
        </Row>

        <Row
          style={{ margin: "0", paddingTop: "50px", minHeight: "100vh" }}
          className="data-section"
        >
          <Col xs={12}>
            <h2
              style={{
                textAlign: "center",
                color: "white",
                fontSize: "1.8rem",
              }}
            >
              Main Key Features
            </h2>
          </Col>

          {labs.map((lab, index) => (
            <Col
              xs={6}
              sm={4}
              md={3}
              key={lab.id}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                padding: "30px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#0D47A1",
                  color: "#fff",
                  height: "60px",
                  width: "300px",
                  borderRadius: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  padding: "5px",
                }}
              >
                <span
                  style={{
                    fontSize: "20",
                    fontWeight: "bold",
                    padding: 2,
                    textAlign: "center",
                  }}
                >
                  {lab.name}
                </span>
              </div>
            </Col>
          ))}
          <Col
            xs={12}
            sm={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              variant="success"
              style={{
                width: 200,
                height: 60,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Book a Demo
            </Button>
          </Col>
        </Row>

        <Row
          data-aos="fade-left"
          style={{ padding: "10px", paddingTop: "80px" }}
        >
          <Col
            xs={12}
            md={8}
            lg={6}
            style={{ padding: "25px", textAlign: "left" }}
          >
            <h1 style={{ fontSize: "1.5rem", fontWeight: "bolder" }}>
              Streamlined Operations
            </h1>
            <p style={{ fontSize: "1rem", display: "flex", margin: "12px" }}>
              Our POS System Management automates essential hospital management
              functions, enabling quick, accurate sales and inventory tracking
              from anywhere via the cloud.
            </p>
            <ul style={{ marginTop: "2", listStyleType: "disc" }}>
              <li>
                <strong style={{ display: "inline" }}>Item Profile –</strong>
                Easily manage item profiles with detailed information on stock,
                pricing, and availability for seamless inventory control.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                  Customer Profile –
                </strong>
                Store and manage customer information, ensuring fast access to
                billing and sales history for personalized service.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                  Supplier Profile –
                </strong>
                Track supplier details, enabling efficient purchase management
                and reliable supplier coordination.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                  Purchase Voucher -
                </strong>
                Automate purchase tracking with cloud-based vouchers that ensure
                accurate and timely documentation of transactions.
              </li>
            </ul>
          </Col>
          <Col
            xs={12}
            md={4}
            lg={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <img
              src={img2}
              alt="PHP Logo"
              style={{ width: "80%", height: "auto" }}
            />
          </Col>
        </Row>

        <Row
          data-aos="fade-left"
          style={{ margin: "20px", padding: "10px", paddingTop: "70px" }}
        >
          <Col
            xs={12}
            md={6}
            lg={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <img
              src={img3}
              alt="PHP Logo"
              style={{ width: "90%", height: "auto" }}
            />
          </Col>
          <Col
            xs={12}
            md={6}
            lg={6}
            style={{
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <h1 style={{ fontSize: "1.5rem", fontWeight: "bolder" }}>
              Data-Driven Efficiency
            </h1>
            <p style={{ fontSize: "1rem", display: "flex", margin: "12px" }}>
              With cloud-based tools, UNSI Tech’s POS System Management offers
              real-time insights and automation to improve financial accuracy
              and operational performance.
            </p>
            <ul style={{ marginTop: "2", listStyleType: "disc" }}>
              <li>
                <strong style={{ display: "inline" }}>Purchase Return –</strong>
                Handle purchase returns seamlessly with real-time tracking and
                automated return processing for efficient stock updates.
              </li>
              <li>
                <strong style={{ display: "inline" }}>Sales Return –</strong>
                Automate sales returns with fast and easy refund processing,
                ensuring customer satisfaction and accurate inventory
                adjustments.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                  Credit/Cash Sales –
                </strong>
                Efficiently manage both credit and cash sales, providing
                flexible payment options while maintaining accurate financial
                records.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                  Outstanding Invoices –
                </strong>
                Monitor and manage outstanding invoices with real-time data,
                ensuring timely payments and improved cash flow.
              </li>
            </ul>
          </Col>
        </Row>

        <Row
          data-aos="fade-left"
          style={{ margin: "0", padding: "10px", position: "relative" }}
        >
          <Col
            xs={12}
            md={6}
            lg={6}
            style={{
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              textAlign: "left",
              backgroundColor: "beige",
              position: "relative",
            }}
          >
            <h1 style={{ fontSize: "1.5rem", fontWeight: "bolder" }}>
              Enhanced Financial Control
            </h1>
            <p style={{ fontSize: "1rem", display: "flex", margin: "12px" }}>
              UNSI Tech’s POS System Management improves financial oversight
              with automated invoice management, payments tracking, and expense
              management for better profitability.
            </p>
            <ul style={{ marginTop: "2", listStyleType: "disc" }}>
              <li>
                <strong style={{ display: "inline" }}>Invoice Aging –</strong>
                Track and analyze overdue invoices with cloud-based aging
                reports to maintain financial health and minimize payment
                delays.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                  Customer Payments -
                </strong>
                Automate customer payment tracking, ensuring accurate
                reconciliation and easy access to payment histories for each
                account.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                  Supplier Payments –
                </strong>
                Streamline supplier payment processes with real-time tracking,
                ensuring timely and accurate payments for all purchases.
              </li>
              <li>
                <strong style={{ display: "inline" }}>Expense Voucher -</strong>{" "}
                Manage and record expenses with automated vouchers, providing
                real-time financial tracking for improved expense control.
              </li>
            </ul>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={6}
            style={{
              padding: "0",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              position: "relative",
            }}
          >
            <img
              src={img1}
              alt="PHP Logo"
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
            />
          </Col>
        </Row>

        <Footer />
      </Container>
    </div>
  );
}

export default POS;
