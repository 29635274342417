import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

import img3 from "../images/medicalbilling/martha-dominguez-de-gouveia-nMyM7fxpokE-unsplash.jpg";
import img4 from "../images/medicalbilling/national-cancer-institute-NFvdKIhxYlU-unsplash.jpg";
import img5 from "../images/medicalbilling/billing dashboard.png";
import img6 from "../images/medicalbilling/medical-enrollment-form-document-medicare-concept.jpg";
import NavBar from "../components/Navbar/NavBar";
import Container from "react-bootstrap/Container";
import Footer from "../components/Footer";
import { Button } from "react-bootstrap";
import MetaTags from "react-meta-tags";
function MedicalBillingSystem() {
  const labs = [
    {
      id: 1,
      name: "Manage Items",
    },
    {
      id: 2,
      name: "Manage Customers",
    },
    {
      id: 3,
      name: "Manage Suppliers",
    },
    {
      id: 4,
      name: "Manage Manufactures",
    },
    {
      id: 5,
      name: "Manage Doctors",
    },
    {
      id: 6,
      name: "Manage Patient",
    },
    {
      id: "7",
      name: "Manage Item Batches",
    },
    {
      id: 8,
      name: "Manage Expiry Item",
    },
    {
      id: 9,
      name: "Manage Purchases",
    },
    {
      id: 10,
      name: "Manage Purchase Return",
    },
    {
      id: 11,
      name: "Manage Sales",
    },
    {
      id: 11,
      name: "Manage Sales Return",
    },
  ];
  return (
    <div>
      <NavBar />
      <Container fluid style={{ padding: "0", margin: "0", maxWidth: "100%" }}>
        <MetaTags>
          <title>UNSI | Medical Billing System</title>
          <meta name="HMS SERVICE" content="Some description." />
          <meta property="og:title" content="MyApp" />
          <meta property="og:image" content="path/to/image.jpg" />
        </MetaTags>
        <Row
          className="data-section"
          data-aos="fade-right"
          style={{ margin: "0", paddingTop: "40px" }}
        >
          <Col
            xs={12}
            md={8}
            lg={6}
            style={{ paddingTop: "25px", padding: "20px" }}
          >
            <h1
              className="text-blue-900"
              style={{
                textAlign: "left",
                fontSize: "2.5rem",
                fontWeight: "bold",
                marginTop: "32px",
              }}
            >
              BEST MEDICAL BILLING SYSTEM MANAGEMENT
            </h1>
            <p>
              UNSI Tech presents the Best Medical Billing System Management,
              designed to streamline and optimize hospital billing processes.
              Powered by advanced ERP software, this system ensures efficient
              management of patients, suppliers, items, and financial
              transactions for a seamless healthcare experience.
            </p>
          </Col>
          <Col
            xs={12}
            md={8}
            lg={6}
            style={{
              paddingTop: "25px",
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={img5}
              alt="Hospital Management"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Col>
        </Row>

        <Row
          style={{ margin: "0", paddingTop: "50px", minHeight: "100vh" }}
          className="data-section"
        >
          <Col xs={12}>
            <h2
              style={{
                textAlign: "center",
                color: "white",
                fontSize: "1.8rem",
              }}
            >
              Main Key Features
            </h2>
          </Col>

          {labs.map((lab, index) => (
            <Col
              xs={6}
              sm={4}
              md={3}
              key={lab.id}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#0D47A1",
                  color: "#fff",
                  height: "60px",
                  width: "300px",
                  borderRadius: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  padding: "5px",
                }}
              >
                <span
                  style={{
                    fontSize: "20",
                    fontWeight: "bold",
                    padding: 2,
                    textAlign: "center",
                  }}
                >
                  {lab.name}
                </span>
              </div>
            </Col>
          ))}
          <Col
            xs={12}
            sm={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              variant="success"
              style={{
                width: 200,
                height: 60,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Book a Demo
            </Button>
          </Col>
        </Row>

        <Row
          data-aos="fade-left"
          style={{ padding: "10px", paddingTop: "80px" }}
        >
          <Col
            xs={12}
            md={8}
            lg={6}
            style={{ padding: "15px", textAlign: "left" }}
          >
            <h1 style={{ fontSize: "1.5rem", fontWeight: "bolder" }}>
              Streamlined Operations
            </h1>
            <p style={{ fontSize: "1rem", display: "flex", margin: "12px" }}>
              Our Medical Billing System simplifies and automates hospital
              billing functions, allowing healthcare providers to operate
              smoothly and enhance service delivery.
            </p>
            <ul style={{ marginTop: "2", listStyleType: "disc" }}>
              <li>
                <strong style={{ display: "inline" }}>Manage Items –</strong>
                Efficiently track and manage medical items and supplies for
                accurate billing and inventory control.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                  Manage Customers –
                </strong>
                Maintain comprehensive records of patients and insurance
                providers for streamlined billing processes.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                  Manage Suppliers –
                </strong>
                Keep organized records of suppliers to ensure timely procurement
                of medical items and supplies.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                  Manage Manufacturers –
                </strong>
                Track and manage manufacturer information for better inventory
                and supply chain management
              </li>
            </ul>
          </Col>
          <Col
            xs={12}
            md={4}
            lg={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <img
              src={img6}
              alt="PHP Logo"
              style={{ width: "80%", height: "auto" }}
            />
          </Col>
        </Row>

        <Row data-aos="fade-left" style={{ margin: "20px", padding: "10px" }}>
          <Col
            xs={12}
            md={6}
            lg={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <img
              src={img3}
              alt="PHP Logo"
              style={{ width: "90%", height: "auto" }}
            />
          </Col>
          <Col
            xs={12}
            md={6}
            lg={6}
            style={{
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
             
            }}
          >
            <h1 style={{ fontSize: "1.5rem", fontWeight: "bolder" }}>
              Data-Driven Efficiency
            </h1>
            <p style={{ fontSize: "1rem", display: "flex", margin: "12px" }}>
              With integrated data tools, UNSI Tech’s Medical Billing System
              Management empowers healthcare facilities to enhance financial
              accuracy and operational efficiency.
            </p>
            <ul style={{ marginTop: "2", listStyleType: "disc" }}>
              <li>
                <strong style={{ display: "inline" }}>Manage Doctors –</strong>
                Maintain detailed records of doctors for accurate billing of services rendered and effective communication..
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                Manage Patients – 
                </strong>
                Track patient information and billing history to provide personalized and accurate billing services.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                Manage Item Batches –
                </strong>
                Monitor and manage batches of items to ensure quality control and compliance with health regulations.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                Manage Expiry Items – 
                </strong>
                Keep track of item expiration dates to ensure the safe use of medical supplies and reduce waste.
              </li>
            </ul>
          </Col>
        </Row>

        <Row
          data-aos="fade-left"
          style={{ margin: "0", padding: "10px", position: "relative" }}
        >
          <Col
            xs={12}
            md={6}
            lg={6}
            style={{
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              textAlign: "left",
              backgroundColor: "beige",
              position: "relative",
            }}
          >
            <h1 style={{ fontSize: "1.5rem", fontWeight: "bolder" }}>
            Enhanced Patient Care
            </h1>
            <p style={{ fontSize: "1rem", display: "flex", margin: "12px" }}>
            UNSI Tech’s Medical Billing System Management is designed to improve patient care through efficient billing processes and accurate financial management.
            </p>
            <ul style={{ marginTop: "2", listStyleType: "disc" }}>
              <li>
                <strong style={{ display: "inline" }}>Manage Purchases –</strong>
                Streamline the purchasing process for medical items with organized records and automated tracking.              </li>
              <li>
                <strong style={{ display: "inline" }}>
                Manage Purchase Returns  – 
                </strong>
                Simplify the return process for purchased items to enhance inventory accuracy and supplier relationships.              </li>
              <li>
                <strong style={{ display: "inline" }}>
                Manage Sales –
                </strong>
                Effectively track sales transactions to ensure accurate billing and financial reporting              </li>
              <li>
                <strong style={{ display: "inline" }}>
                Manage Sales Returns -
                </strong>
                Handle sales returns efficiently to maintain accurate records and improve customer satisfaction              </li>
            </ul>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={6}
            style={{
              padding: "0",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              position: "relative",
            }}
          >
            <img
              src={img4}
              alt="PHP Logo"
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
            />
          </Col>
        </Row>

        <Footer />
      </Container>
    </div>
  );
}

export default MedicalBillingSystem;
