import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from 'react';
import android1 from "../images/doctor-nurses-special-equipment-removebg-preview.png";
import android2 from "../images/Hospital-Management-System.jpg";
import android5 from "../images/doc.jpg";
import android4 from "../images/docs.jpg";
import { Button } from "react-bootstrap";

import NavBar from '../components/Navbar/NavBar';
import Container from "react-bootstrap/Container";
import Footer from "../components/Footer";
import MetaTags from "react-meta-tags";
function HospitalManagementPage() {
  const labs = [
    {
      id: 1,
      name: "Appointment Management",
    },
    {
      id: 2,
      name: "Inventory",
    },
    {
      id: 3,
      name: "Billing Management",
    },
    {
      id: 4,
      name: "Lab Management",
    },
    {
      id: 5,
      name: "Analytics Tools",
    },
    {
      id: 6,
      name: "Patient Registration",
    },
    {
      id: "7",
      name: "Pharmacy Management",
    },
    {
      id: 8,
      name: "Staff Management",
    },
    {
      id: 9,
      name: "Prescription Management",
    },
    {
      id: 10,
      name: "Data Security",
    },
    {
      id: 11,
      name: "Improved Services",
    },
    {
      id: 11,
      name: "Customer Service",
    },
  ];
  return (
    <div>
      <NavBar />
      <Container fluid style={{ padding: '0' , margin:'0' , maxWidth:'100%' }}>
      <MetaTags>
          <title>UNSI | Hospital management system</title>
          <meta name="HMS SERVICE" content="Some description." />
          <meta property="og:title" content="MyApp" />
          <meta property="og:image" content="path/to/image.jpg" />
        </MetaTags>
        <Row className="data-section" data-aos="fade-right" style={{ margin: "0", paddingTop: "70px" }}>
          <Col xs={12} md={8} lg={6} style={{ paddingTop: "25px", padding: "20px" }}>
            <h1 className="text-blue-900" style={{ textAlign: "left", fontSize: "2.5rem", fontWeight: "bold", marginTop: '32px' }}>
              BEST HOSPITAL MANAGEMENT SYSTEM
            </h1>
            <p style={{marginTop:'16px' , fontSize:'1.2rem'}} >At UNSI Tech, we’ve built the Best Hospital Management System to transform healthcare facilities through seamless integration and automation. Powered by advanced ERP software, our system enhances hospital operations by optimizing workflows, improving data management, and elevating patient care.
            </p>
          </Col>
          <Col xs={12} md={8} lg={6} style={{ paddingTop: "35px", padding: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={android1} alt="Hospital Management" style={{ maxWidth: '100%', height: '350px' }} />
          </Col>
        </Row>
        <Row
          style={{ margin: "0", paddingTop: "50px", minHeight: "100vh" }}
          className="data-section"
        >
          <Col xs={12}>
            <h2
              style={{
                textAlign: "center",
                color: "white",
                fontSize: "1.8rem",
              }}
            >
              Main Key Features
            </h2>
          </Col>

          {labs.map((lab, index) => (
            <Col
              xs={6}
              sm={4}
              md={3}
              key={lab.id}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#0D47A1",
                  color: "#fff",
                  height: "60px",
                  width: "300px",
                  borderRadius: "12px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                <span
                  style={{ fontSize: "20", fontWeight: "bold", padding: 2 , textAlign:'center' }}
                >
                  {lab.name}
                </span>
              </div>
            </Col>
          ))}
          <Col
            xs={12}
            sm={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Button
              variant="success"
              style={{
                width: 200,
                height: 60,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Book a Demo
            </Button>
          </Col>
        </Row>
        {/* <Row style={{ margin: "0", paddingTop: "50px", minHeight: "100vh" }} className="data-section">
  <Col xs={12}>
    <h2 style={{ textAlign: 'center', color: 'white', fontSize: '1.8rem' }}>MAIN KEY FEATURES</h2>
  </Col>

  {/* Row 1 */}
  {/* <Row style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
  <Col xs={6} sm={4} md={3} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
  <div style={{ backgroundColor: '#0D47A1', height: '65px', width: '175px', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <h5 style={{ margin: '0', color: 'white', textAlign: 'center' }}>Appointment management</h5>
  </div>
</Col>
<Col xs={6} sm={4} md={3} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
  <div style={{ backgroundColor: '#0D47A1', height: '65px', width: '175px', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <h5 style={{ margin: '0', color: 'white', textAlign: 'center' }}>Inventory</h5>
  </div>
</Col>
<Col xs={6} sm={4} md={3} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
  <div style={{ backgroundColor: '#0D47A1', height: '65px', width: '175px', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <h5 style={{ margin: '0', color: 'white', textAlign: 'center' }}>Billing management</h5>
  </div>
</Col>
<Col xs={6} sm={4} md={3} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
  <div style={{ backgroundColor: '#0D47A1', height: '65px', width: '175px', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <h5 style={{ margin: '0', color: 'white', textAlign: 'center' }}>Lab management</h5>
  </div>
</Col>
  </Row>

  {/* Row 2 */}
  {/* <Row style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
  <Col xs={6} sm={4} md={3} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
  <div style={{ backgroundColor: '#0D47A1', height: '65px', width: '175px', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <h5 style={{ margin: '0', color: 'white', textAlign: 'center' }}>Analytics tools</h5>
  </div>
</Col>
<Col xs={6} sm={4} md={3} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
  <div style={{ backgroundColor: '#0D47A1', height: '65px', width: '175px', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' , padding:'5px' }}>
    <h5 style={{ margin: '0', color: 'white', textAlign: 'center' }}>Patient registration</h5>
  </div>
</Col>
<Col xs={6} sm={4} md={3} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
  <div style={{ backgroundColor: '#0D47A1', height: '65px', width: '175px', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <h5 style={{ margin: '0', color: 'white', textAlign: 'center' }}>Pharmacy management</h5>
  </div>
</Col>
<Col xs={6} sm={4} md={3} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
  <div style={{ backgroundColor: '#0D47A1', height: '65px', width: '175px', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' , padding:'4px' }}>
    <h5 style={{ margin: '0', color: 'white', textAlign: 'center' }}>Staff Management</h5>
  </div>
</Col>
  </Row> */}

  {/* Row 3 */}
  {/* <Row style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
  <Col xs={6} sm={4} md={3} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
  <div style={{ backgroundColor: '#0D47A1', height: '65px', width: '175px', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <h5 style={{ margin: '0', color: 'white', textAlign: 'center' }}>Prescription management</h5>
  </div>
</Col>
<Col xs={6} sm={4} md={3} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
  <div style={{ backgroundColor: '#0D47A1', height: '65px', width: '175px', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <h5 style={{ margin: '0', color: 'white', textAlign: 'center' }}>Data security</h5>
  </div>
</Col>
<Col xs={6} sm={4} md={3} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
  <div style={{ backgroundColor: '#0D47A1', height: '65px', width: '175px', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <h5 style={{ margin: '0', color: 'white', textAlign: 'center' }}>Improved services</h5>
  </div> */}
{/* </Col>
<Col xs={6} sm={4} md={3} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px' }}>
  <div style={{ backgroundColor: '#0D47A1', height: '65px', width: '175px', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <h5 style={{ margin: '0', color: 'white', textAlign: 'center' }}>Customer service</h5>
  </div>
</Col>
<Button */}
              {/* variant="success"
              style={{
                width: 200,
                height: 60,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Book a Demo
            </Button>
  </Row> */}
{/* {/* </Row> */ }


        <Row data-aos='fade-left' style={{ padding: '10px' }}>
        <Col xs={12} md={8} lg={6} style={{ padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
  <div style={{ display: 'flex', flexDirection: 'column',  justifyContent: 'start', margin:'25px' }}>
    <h1 style={{ fontSize: '1.5rem', fontWeight: 'bolder', marginBottom: '10px' , display:'flex', justifyContent:'start' }}>
    Streamlined Operations
    </h1>
    <p style={{ fontSize: '1rem',display:'flex'  }}>
    Efficiency is at the heart of our system. UNSI Tech’s Best Hospital Management System ensures that everyday hospital operations run smoothly, saving time and resources while improving service delivery. 
    </p>
    <ul style={{marginTop:"2" , listStyleType:'disc'}} >
    <li> <strong style={{display:'inline'}}>Appointment Management-</strong>    Optimize patient bookings and minimize scheduling conflicts with automated management tools.</li>
    <li><strong style={{display:'inline'}}>Inventory Management-</strong> Monitor medical supplies in real-time, preventing shortages and ensuring availability.</li>
    <li> <strong style={{display:'inline'}}>Billing Management-</strong>  Simplify invoicing, payments, and insurance claims with efficient automated billing.</li>
    <li><strong style={{display:'inline'}}>Lab Management –</strong> Manage lab tests, samples, and results seamlessly with advanced lab management tools. </li>  </ul>
  </div>
</Col>


          <Col xs={12} md={4} lg={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
            <img src={android5} alt='PHP Logo' style={{ width: '100%', height: 'auto' }} />
          </Col>
        </Row>

        <Row data-aos='fade-left' style={{ margin: '20px', padding: '10px' }}>
          <Col xs={12} md={6} lg={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
            <img src={android4} alt='PHP Logo' style={{ width: '90%', height: 'auto' }} />
          </Col>
          <Col xs={12} md={6} lg={6} style={{ padding: '15px', display: 'flex', flexDirection: 'column', justifyContent: 'center',  }}>
            <h1 style={{ fontSize: '1.5rem', fontWeight: 'bolder' }}>
            Data-Driven Efficiency
            </h1>
            <p style={{ fontSize: '1rem',display:'flex'  }}>
            With UNSI Tech’s ERP-powered system, hospitals gain access to powerful data tools that drive informed decisions, improve operational performance, and provide greater insight into hospital functions.    </p>
    <ul style={{marginTop:"2" , listStyleType:'disc'}} >
    <li> <strong style={{display:'inline'}}>Analytics Tools –</strong> Leverage real-time analytics to enhance decision-making and operational efficiency.</li>
    <li><strong style={{display:'inline'}}>Patient Registration –</strong> Ensure fast, accurate patient registration with seamless data integration and storage.</li>
    <li> <strong style={{display:'inline'}}>Pharmacy Management-</strong> Streamline medicine inventory and prescriptions with real-time pharmacy tools.</li>
    <li><strong style={{display:'inline'}}>Staff Management –</strong>Efficiently manage staff scheduling, task assignments, and payroll with integrated tools </li>  </ul>         </Col>
        </Row>

        <Row data-aos="fade-left" style={{ margin: "0", padding: "10px", position: "relative" }}>
          <Col xs={12} md={6} lg={6} style={{ padding: "15px", display: "flex", flexDirection: "column", justifyContent: "flex-start", textAlign: "left", backgroundColor: "beige", position: "relative" }}>
            <h1 style={{ fontSize: "1.5rem", fontWeight: "bolder" }}>
            Enhanced Patient Care
            </h1>
            <p style={{ fontSize: '1rem',display:'flex'  }}>
            Our system is designed to elevate the overall patient experience. From secure data management to improved service delivery, UNSI Tech’s solution helps hospitals provide exceptional care that patients can trust.    </p>
    <ul style={{  listStyleType:'disc'}} >
    <li> <strong style={{display:'inline'}}>Prescription Management –</strong> Easily generate and transmit prescriptions digitally for error-free medication management.</li>
    <li><strong style={{display:'inline'}}>Data Security – </strong> Protect patient information with strong encryption and compliance with healthcare regulations.</li>
    <li> <strong style={{display:'inline'}}>Improved Services – </strong> Boost patient satisfaction and reduce waiting times with optimized service workflows.</li>
    <li><strong style={{display:'inline'}}>Customer Service –</strong>Enhance patient support through efficient customer service and timely follow-ups. </li>  </ul>  
          </Col>
          <Col xs={12} md={6} lg={6} style={{ padding: "0", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", position: "relative" }}>
            <img src={android2} alt="PHP Logo" style={{ height:'400px', width: "100%", objectFit: "cover" }} />
          </Col>
        </Row>

        <Footer />
      </Container>
    </div>
  );
}

export default HospitalManagementPage;
