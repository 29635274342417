import React from 'react';
import kws from '../images/clients/kws.png';
import geps from '../images/clients/geps.png';
import protergia from '../images/clients/protergia.png';
import wellness from '../images/clients/american-wellness-center-logo.png';
// import doctor from '../images/clients/9b6a2316-a7f2-4b38-b51b-08cc7c835b22.jpeg';
import gcnkaa from '../images/clients/gcnkaa-black-logo.png';
// import webpg from '../images/clients/logo.webp';
// import Nreia from '../images/clients/NREIA-LOGO-short-1.webp';
import './Hero.css';

const clientImage = {
  height: '10rem', // Set a fixed height
  width: 'auto',   // Maintain aspect ratio
  marginLeft: '120px',
};

const Clients = () => {
  return (
    <div className="mt-8 bg-gray-100">
      <section data-aos="fade-up">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
            Our Clients
          </h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
            Some of our clients.
          </h2>
        </div>

        {/* Continuous scrolling section */}
        <div className="scrolling-wrapper">
          <div className="scroll-content">
            {/* Client Logos */}
            <img style={clientImage} src={kws} alt="client" />
            <img style={clientImage} src={geps} alt="client" />
            <img style={clientImage} src={wellness} alt="client" />
            <img style={clientImage} src={protergia} alt="client" />
            <img style={clientImage} src={gcnkaa} alt="client" />
            {/* Repeat the images for smooth infinite scroll */}
            <img style={clientImage} src={kws} alt="client" />
            <img style={clientImage} src={geps} alt="client" />
            <img style={clientImage} src={wellness} alt="client" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Clients;
