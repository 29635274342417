import React from "react";
import { Link } from "react-router-dom";

const Portfolio = () => {
  return (
    <>
      <div className="my-4 py-4" id="portfolio">
        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
          Products
        </h2>

        <div
          className="px-3 lg:mx-10"
          data-aos="fade-down"
          data-aos-delay="600"
        >
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {/* First Card */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 flex flex-col justify-between min-h-[400px]">
              <div className="m-2 text-justify text-sm">
                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-15">
                  HOSPITAL MANAGEMENT SYSTEM
                </h4>
                <p className="text-md font-medium leading-5 h-auto md:h-48">
                  At UNSI Tech, we’ve built the Best Hospital Management System
                  to transform healthcare facilities through seamless
                  integration and automation. Powered by advanced ERP software,
                  our system enhances hospital operations by optimizing
                  workflows, improving data management, and elevating patient
                  care.
                </p>
              </div>
              <div className="flex justify-center my-4">
                <Link
                  to="/contact"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 text-lg shadow-xl rounded-xl"
                >
                  Schedule Demo
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>

            {/* Second Card */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 flex flex-col justify-between min-h-[400px]">
              <div className="m-2 text-justify text-sm">
                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-15">
                  LAB <br/>SYSTEM MANAGEMENT
                </h4>
                <p className="text-md font-medium leading-5 h-auto md:h-48">
                  UNSI Tech presents the Best Lab Management System, designed to
                  streamline and optimize laboratory operations. Powered by
                  cutting-edge ERP software, this system offers seamless
                  management of patient profiles, lab tests, inventory, and much
                  more, ensuring efficiency and accuracy in every aspect of lab
                  management.
                </p>
              </div>
              <div className="flex justify-center my-4">
                <Link
                  to="/contact"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 text-lg shadow-xl rounded-xl"
                >
                  Schedule Demo
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 flex flex-col justify-between min-h-[400px]">
              <div className="m-2 text-justify text-sm">
                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-15">
                  MEDICAL BILLING SYSTEM MANAGEMENT
                </h4>
                <p className="text-md font-medium leading-5 h-auto md:h-48">
                  UNSI Tech presents the Best Medical Billing System Management,
                  designed to streamline and optimize hospital billing
                  processes. Powered by advanced ERP software, this system
                  ensures efficient management of patients, suppliers, items,
                  and financial transactions for a seamless healthcare
                  experience.
                </p>
              </div>
              <div className="flex justify-center my-4">
                <Link
                  to="/contact"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 text-lg shadow-xl rounded-xl"
                >
                  Schedule Demo
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 flex flex-col justify-between min-h-[400px]">
              <div className="m-2 text-justify text-sm">
                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-15">
                  POS <br/>SYSTEM MANAGEMENT
                </h4>
                <p className="text-md font-medium leading-5 h-auto md:h-48">
                  UNSI Tech introduces the Best POS System Management
                  (Cloud-Based), designed to optimize sales, inventory, and
                  financial processes for hospital management. Powered by robust
                  ERP software, this cloud-based system offers real-time access
                  to essential business operations, ensuring efficiency and
                  accuracy across all transactions
                </p>
              </div>
              <div className="flex justify-center my-4">
                <Link
                  to="/contact"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 text-lg shadow-xl rounded-xl"
                >
                  Schedule Demo
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
