import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import img1 from "../images/POS/POSMAIN.jpeg";
import img3 from "../images/POS/Barcode-Scanning-in-the-Warehouse-scaled.jpeg";
import img4 from "../images/POS/POSDESKTOP.jpg";
import img5 from "../images/POS/WhatsApp Image 2024-09-11 at 8.24.32 AM.jpeg";
import NavBar from "../components/Navbar/NavBar";
import Container from "react-bootstrap/Container";
import Footer from "../components/Footer";
import { Button } from "react-bootstrap";
import MetaTags from "react-meta-tags";
function POSDESKTOP() {
    const labs = [
        {
          id: 1,
          name: "Item Profile",
        },
        {
          id: 2,
          name: "Customer Profile",
        },
        {
          id: 3,
          name: "Supplier Profile",
        },
        {
          id: 4,
          name: "Purchase Voucher",
        },
        {
          id: 5,
          name: "Purchase Return",
        },
        {
          id: 6,
          name: "Sales Return",
        },
        {
          id: "7",
          name: "Credit/Cash Sales",
        },
        {
          id: 8,
          name: "Outstanding Invoices",
        },
        {
          id: 9,
          name: "Invoice Aging",
        },
        {
          id: 10,
          name: "Customer Payments",
        },
        {
          id: 11,
          name: "Supplier Payments",
        },
        {
          id: 12,
          name: "Expense Voucher",
        },
      ];
    
      return (
        <div>
          <NavBar />
          <Container fluid style={{ padding: "0", margin: "0", maxWidth: "100%" }}>
          <MetaTags>
          <title>UNSI | POS (Desktop) System</title>
          <meta name="POS SERVICE" content="Some description." />
          <meta property="og:title" content="MyApp" />
          <meta property="og:image" content="path/to/image.jpg" />
        </MetaTags>
            <Row
              className="data-section"
              data-aos="fade-right"
              style={{ margin: "0", paddingTop: "70px" }}
            >
              <Col
                xs={12}
                md={8}
                lg={5}
                style={{ paddingTop: "15px", padding: "20px" }}
              >
                <h1
                  className="text-blue-900"
                  style={{
                    textAlign: "left",
                    fontSize: "2.5rem",
                    fontWeight: "bold",
                    marginTop: "32px",
                  }}
                >
                  BEST POS SYSTEM MANAGEMENT DESKTOP BASED
                </h1>
                <p>UNSI Tech introduces the Best POS System Management (Desktop-Based), designed to enhance sales, inventory, and financial operations within hospital management. This desktop-based system, integrated with advanced ERP software, provides a robust and efficient platform for managing key processes, all from the convenience of a secure desktop environment.</p>
              </Col>
              <Col
                xs={12}
                md={8}
                lg={7}
                style={{
                  paddingTop: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={img4}
                  alt="Hospital Management"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </Col>
            </Row>
    
            <Row
              style={{ margin: "0", paddingTop: "50px", minHeight: "100vh" }}
              className="data-section"
            >
              <Col xs={12}>
                <h2
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontSize: "1.8rem",
                  }}
                >
                  Main Key Features
                </h2>
              </Col>
    
              {labs.map((lab, index) => (
                <Col
                  xs={6}
                  sm={4}
                  md={3}
                  key={lab.id}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    padding: "30px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#0D47A1",
                      color: "#fff",
                      height: "60px",
                      width: "300px",
                      borderRadius: "12px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
    
                      padding: "5px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "20",
                        fontWeight: "bold",
                        padding: 2,
                        textAlign: "center",
                      }}
                    >
                      {lab.name}
                    </span>
                  </div>
                </Col>
              ))}
              <Col
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="success"
                  style={{
                    width: 200,
                    height: 60,
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  Book a Demo
                </Button>
              </Col>
            </Row>
    
            <Row
              data-aos="fade-left"
              style={{ padding: "10px", paddingTop: "80px" }}
            >
              <Col
                xs={12}
                md={8}
                lg={6}
                style={{ padding: "25px", textAlign: "left" }}
              >
                <h1 style={{ fontSize: "1.5rem", fontWeight: "bolder" }}>
                Streamlined Operations
                </h1>
                <p style={{ fontSize: "1rem", display: "flex", margin: "12px" }}>
                Our POS System Management (Desktop-Based) enables seamless and accurate management of inventory, customers, and suppliers, ensuring smooth operations and effective control.
             </p>
             <ul style={{ marginTop: "2", listStyleType: "disc" }}>
               <li>
                 <strong style={{ display: "inline" }}>
                 Item Profile  – 
                 </strong>
                 Manage item profiles effortlessly, including stock levels, pricing, and availability, to maintain accurate inventory control.
               </li>
               <li>
                 <strong style={{ display: "inline" }}>
                 Customer Profile –
                 </strong>
                 Store detailed customer information, enabling fast access to billing history and personalized service for better customer relations.
               </li>
               <li>
                 <strong style={{ display: "inline" }}>Supplier Profile – </strong>
                 Track and manage supplier profiles to ensure smooth procurement and efficient restocking processes.
               </li>
               <li>
                 <strong style={{ display: "inline" }}>
                 Purchase Voucher -
                 </strong>
                 Automate the creation and tracking of purchase vouchers, ensuring all purchases are documented accurately.
               </li>
             </ul>
              </Col>
              <Col
                xs={12}
                md={4}
                lg={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <img
                  src={img5}
                  alt="PHP Logo"
                  style={{ width: "80%", height: "auto" }}
                />
              </Col>
            </Row>
    
            <Row
              data-aos="fade-left"
              style={{ margin: "20px", padding: "10px", paddingTop: "70px" }}
            >
              <Col
                xs={12}
                md={6}
                lg={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <img
                  src={img3}
                  alt="PHP Logo"
                  style={{ width: "90%", height: "auto" }}
                />
              </Col>
              <Col
                xs={12}
                md={6}
                lg={6}
                style={{
                  padding: "15px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  
                }}
              >
                <h1 style={{ fontSize: "1.5rem", fontWeight: "bolder" }}>
                Data-Driven Efficiency

                </h1>
                <p style={{ fontSize: "1rem", display: "flex", margin: "12px" }}>
                With real-time desktop management, UNSI Tech’s POS System improves data accuracy and efficiency in handling purchases, returns, and financial transactions.
            </p>
            <ul style={{ marginTop: "2", listStyleType: "disc" }}>
              <li>
                <strong style={{ display: "inline" }}>
                Purchase Return – 
                </strong>
                Efficiently manage purchase returns with automated tracking and accurate stock adjustments in real-time.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                Sales Return–
                </strong>
                Streamline sales returns, ensuring quick refunds and accurate updates to your inventory for better financial tracking.
              </li>
              <li>
                <strong style={{ display: "inline" }}>Credit/Cash Sales –</strong>
                Easily handle both credit and cash sales with accurate tracking, enabling flexible payment options for customers.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                Outstanding Invoices-
                </strong>
                Monitor and manage outstanding invoices efficiently, ensuring timely follow-ups and better cash flow management.
              </li>
            </ul>
              </Col>
            </Row>
    
            <Row
              data-aos="fade-left"
              style={{ margin: "0", padding: "10px", position: "relative" }}
            >
              <Col
                xs={12}
                md={6}
                lg={6}
                style={{
                  padding: "15px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  backgroundColor: "beige",
                  position: "relative",
                }}
              >
                <h1 style={{ fontSize: "1.5rem", fontWeight: "bolder" }}>
                Enhanced Financial Control
                </h1>
                <p style={{ fontSize: "1rem", display: "flex", margin: "12px" }}>
                UNSI Tech’s Desktop-Based POS System enhances financial control with automated tracking of invoices, payments, and expenses, ensuring precision and streamlined processes.
             </p>
             <ul style={{ marginTop: "2", listStyleType: "disc" }}>
               <li>
                 <strong style={{ display: "inline" }}>
                 Invoice Aging– 
                 </strong>
                 Track overdue invoices and monitor their status to ensure timely payments and prevent cash flow disruptions.
                 
               </li>
               <li>
                 <strong style={{ display: "inline" }}>
                 Customer Payments –
                 </strong>
                 Automate customer payment tracking, providing a clear view of payment histories and ensuring accurate reconciliations.
               </li>
               <li>
                 <strong style={{ display: "inline" }}>Supplier Payments –</strong>
                 Manage supplier payments efficiently, ensuring timely and accurate disbursements for purchased goods and services.
               </li>
               <li>
                 <strong style={{ display: "inline" }}>
                 Expense Voucher -
                 </strong>
                 Easily record and track expenses with automated voucher creation, ensuring clear documentation and expense control.  
               </li>
             </ul>
              </Col>
              <Col
                xs={12}
                md={6}
                lg={6}
                style={{
                  padding: "0",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  position: "relative",
                }}
              >
                <img
                  src={img1}
                  alt="PHP Logo"
                  style={{ width: "100%", height: "auto", objectFit: "cover" }}
                />
              </Col>
            </Row>
    
            <Footer />
          </Container>
        </div>
      );
    }

export default POSDESKTOP
