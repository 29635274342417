import React from "react";
import Navbar from "./Navbar/NavBar";
import { Container, Row } from "react-bootstrap";
function PrivacyPolicy() {
  return (
    <>
      <div>
        <Navbar />
      </div>
      <Container>
        <Row>
          <h1 style={{marginTop:50}} >Privacy Policy </h1>
          <h4>Effective Date: 9/23/2024</h4>
          <p>
            At UNSI Tech, we are committed to protecting your privacy and
            ensuring the security of your personal information. This Privacy
            Policy outlines how we collect, use, and safeguard the information
            you provide to us.
          </p>
          <h4>1. Information We Collect</h4>
          <p>We may collect the following types of information</p>
          <ul style={{listStyleType:'disc', marginLeft: 20}} >
            <li>
              Personal identification details (e.g., name, email, phone number)
            </li>
            <li>Usage data (e.g., browser type, IP address, pages viewed)</li>
            <li>Communication preferences</li>
            <li>
              Other data relevant to customer support or service improvements
            </li>
          </ul>
          <h4>2. How We Use Your Information</h4>
          <p>We use the information we collect for:</p>
          <ul style={{listStyleType:'disc', marginLeft: 20}}>
            <li>Providing and maintaining our services</li>
            <li>Enhancing user experience</li>
            <li>Processing transactions and payments</li>
            <li>
              Sending updates, marketing communications, and service
              announcements
            </li>
            <li>Ensuring the security of our platform</li>
          </ul>
          <h4>3. Data Protection and Security</h4>
          <p>
            We prioritize the security of your data and take appropriate
            measures to protect it from unauthorized access, disclosure, or
            destruction. We use advanced encryption and secure storage practices
            to maintain confidentiality.
          </p>
          <h4>4. Sharing of Information</h4>
          <p>
            We do not sell or share your personal information with third
            parties, except:
          </p>
          <ul style={{listStyleType:'disc', marginLeft: 20}}>
            <li>
              When necessary for service delivery (e.g., payment processors)
            </li>
            <li>To comply with legal obligations</li>
            <li>
              With trusted partners who agree to keep the information
              confidential
            </li>
          </ul>
          <h4>5. Cookies and Tracking Technologies</h4>
          <p>
            We may use cookies and similar technologies to enhance your
            experience, analyze site traffic, and personalize content. You can
            adjust your browser settings to manage cookies.
          </p>
          <h4>6. Your Rights</h4>
          <p>You have the right to:</p>
          <ul style={{listStyleType:'disc', marginLeft: 20}}>
            <li>Access, update, or delete your personal information</li>
            <li>Opt-out of marketing communications</li>
            <li>Request clarification about how your data is used</li>
          </ul>
          <h4>7. Changes to This Policy</h4>
          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be reflected with a revised "Effective Date" and posted on our
            website.
          </p>
          <h4>8. Contact Us</h4>
          <p>If you have any questions or concerns regarding this Privacy Policy, please contact us.</p>
          <div className=" mt-3 mb-4 text font-semibold py-1">
              Copyright &copy; {new Date().getFullYear()}
            
              
                UNSI TECH
              
              . All rights reserved.
            </div>
        </Row>
      </Container>
    </>
  );
}

export default PrivacyPolicy;
